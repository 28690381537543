//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import {fetchCommissionCODetail, saveCommissionCO} from "@/api/commission";
import {Message} from "element-ui";

const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})

export default {
	name: 'CommissionDetail',
	components: {
		ContractDetail
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.usersOpened = from.fullPath.includes('CheckDocument')
		})
	},
	data() {
		return {
			fields: [
				{key: 'id', label: '', _style: 'width:100px'},
				{key: 'name', label: 'รายการ', _style: 'min-width:100px'},
				{key: 'price', label: 'จำนวนเงิน', _style: 'width: 200px; text-align:right'}
			],
			items: [],
			loanType: ['ไฟแนนซ์', 'ที่ดิน', 'p_loan', 'โอนรถแล้ว'],
			status: [{value: 1, label: 'เรียบร้อย'}, {value: 2, label: 'ไม่เรียบร้อย'}],
			causeOption: ['', 'เอกสารเซ็นไม่ครบ', 'กุญเเจสำรอง', 'ใบตรวจสภาพ', 'คู่มือ'],
			isLoading: false,
			dialogImageUrl: '',
			dialogVisible: false,
			temp: {
				attachments: []
			},
			formData: {
				commission: 0.00,
				oil_cost: 0.00,
				other_cost: 0.00,
				message: '',
				sumPrice: 0.00,
				transport_date: '',
				receive_date: ''
			},
			largeModal: false,
			param: {
				contractId: this.$route.params.id
			},
			onLoad: false,
		}
	},
	computed: {
		contractDetail() {
			return this.items;
		},
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await this.getCommissionDetail();
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async getCommissionDetail() {
			await fetchCommissionCODetail(this.param).then(res => {
				this.items = []
				const check = res.header;
				const data = res.body;
				if (check.error === 'N') {
					data[0].balance = Number(data[0].balance).toLocaleString('en', this.balanceFormat);
					this.formData.contractId = data[0].contract_id;
					this.formData.contractTypeId = data[0].contract_type_id;
					this.formData.commission = data[0].commission;
					this.formData.oil_cost = data[0].oil_cost;
					this.formData.other_cost = data[0].other_cost;
					this.formData.message = data[0].message;
					this.formData.transport_date = data[0].transport_date ? data[0].transport_date : '';
					this.formData.receive_date = data[0].receive_date ? data[0].receive_date : '';

					this.items = data[0];
				}
				console.log(this.items)
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log('error :', error)
			})
		},
		saveData() {
			saveCommissionCO(this.formData).then(response => {
				const check = response.header
				// const data = response.body
				if (check.error === 'N') {
					Message({
						message: 'บันทึกข้อมูลสำเร็จ',
						type: 'success',
						duration: 2 * 1000,
						onClose: () => {
							this.goBack()
						}
					})
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 3 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		validator(val) {
			return val ? val.length >= 4 : false
		},
		async beforeUpload(file) {
			const data = await toBase64(file)
			this.form.images.push(data)
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url
			this.dialogVisible = true
		},
		async handleRemove(file, fileList) {
			console.log(fileList)
			if (this.form.images.length > 0) {
				for (let i = 0; i < this.form.images.length; i++) {
					if (this.form.images[i] === (await toBase64(file.raw))) {
						this.form.images.splice(i, 1)
						i--
					}
				}
			} else if (Number(this.temp.attachments.length) > 0) {
				for (let index = 0; index < this.temp.attachments.length; index++) {
					if (this.temp.attachments[index].name === file.name) {
						this.form.imagesDel.push(file.name)
					}
				}
			}
		},
		goBack() {
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/stepCO/Commission'})
		}
	},
}
