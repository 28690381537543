import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchCommissionHR(params) {
    return request({
      url: baseURL + '/commission/hr/list',
      method: 'GET',
      params: params
    })
  }

export function fetchCommissionCO(param) {
  return request({
    url: baseURL + '/commission/co/list',
    method: 'GET',
    params: param
  })
}

export function fetchCommissionHRDetail(params) {
  return request({
    url: baseURL + '/commission/hr/detail',
    method: 'GET',
    params: params
  })
}

export function fetchCommissionCODetail(param) {
  return request({
    url: baseURL + '/commission/co/detail',
    method: 'GET',
    params: param
  })
}

export function saveCommissionHR(data) {
  return request({
    url: baseURL + '/commission/hr/save',
    method: 'POST',
    data
  })
}

export function saveCommissionCO(data) {
  return request({
    url: baseURL + '/commission/co/save',
    method: 'POST',
    data
  })
}
